import React from "react";
import Logo from "../logo";
import { PhoneIcon } from "@heroicons/react/24/outline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTelegram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";

export default function FooterSection() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center w-full bg-neutral-800 font-raleway">
      <div className="flex flex-col w-[80%] sm:w-[90%] lg:px-8 lg:w-screen max-w-[1280px] items-center py-10 mt-10  gap-14">
        <div className="flex flex-col sm:flex-row justify-end items-start gap-10 sm:gap-0 sm:justify-between sm:items-end text-neutral-400 w-full">
          <div className="flex flex-col items-start gap-4">
            <Logo height={"40px"} color={"#b2b2b2"} />
            <span className="text-[12px] md:text-[14px] font-space font-thin">
              {`© 2023 Foresty. ${t("copyright")}`}
            </span>
          </div>
          <div className="flex flex-col items-start sm:items-end gap-3">
            <div className="flex flex-row text-[18px] md:text-[24px] gap-3 md:gap-5">
              <a target="_blank" href="https://t.me/foresty_uz">
                <FontAwesomeIcon
                  icon={faTelegram}
                  className="text-neutral-400 hover:text-green-500"
                />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/foresty.uz/"
                className=" hover:text-green-500"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  className="text-neutral-400 hover:text-green-500"
                />
              </a>

              {/* <a href="" className=" hover:text-green-500">
                <FontAwesomeIcon
                  icon={faFacebook}
                  className="text-neutral-400 hover:text-green-500"
                />
              </a>
              <a href="" className=" hover:text-green-500">
                <FontAwesomeIcon
                  icon={faTwitter}
                  className="text-neutral-400 hover:text-green-500"
                />
              </a>
              <a href="" className=" hover:text-green-500">
                <FontAwesomeIcon
                  icon={faYoutube}
                  className="text-neutral-400 hover:text-green-500"
                />
              </a> */}
            </div>
            <div className="flex flex-row font-space gap-2 items-center">
              <PhoneIcon className="w-[20px]" />
              <span className="text-[14px] md:text-[18px]">
                {"+998 90 885 76 55"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
