import React from "react";
import GroupCard from "../group/groupCard";

export default function Groups({ groups }) {
  return (
    <div className="flex flex-row justify-center flex-wrap gap-8 w-full bg-neutral-700/20 backdrop-blur-lg border-white/10 border-[0.3px] p-8 rounded-2xl ">
      {groups &&
        groups.map((el, index) => <GroupCard key={index} group={el} />)}
    </div>
  );
}
