import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export default function MainHeader() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col md:mt-20 relative z-10 w-full text-[36px] text-left md:text-center md:text-[48px] lg:text-[56px] h-[340px] text-white font-raleway font-bold justify-center items-start md:items-center md:ml-0 ml-0 px-14">
      {/* <div className="absolute bg-[url('./assets/sketch/monitor_sketch.svg')] bg-fit bg-center bg-no-repeat w-[350px] h-[230px] bottom-[-80px] left-0" />
      <div className="absolute bg-[url('./assets/sketch/old_calc.svg')] bg-fit bg-center bg-no-repeat w-[200px] h-[180px] top-[50px] left-[130px] rotate-[10deg]" />
      <div className="absolute bg-[url('./assets/sketch/calendar.svg')] bg-fit bg-center bg-no-repeat w-[280px] h-[200px] bottom-[-50px] right-10" />
      <div className="absolute bg-[url('./assets/sketch/documents.svg')] bg-fit bg-center bg-no-repeat w-[300px] h-[200px] top-[24px] right-10" /> */}
      {i18next.resolvedLanguage === "uz" ? (
        <>
          <span>{"O'quv markazlar "}</span>
          <span className="">
            {"uchun "}
            <span>
              <span className="linear-wipe">{"online "}</span>
              <span className="text-white">{"platforma"}</span>
            </span>
          </span>
        </>
      ) : i18next.resolvedLanguage === "en" ? (
        <>
          <span>{"Online platform"}</span>
          <span className="">
            {"for "}
            <span>
              <span className="linear-wipe">{"educational "}</span>
              <span className="text-white">{" centers"}</span>
            </span>
          </span>
        </>
      ) : (
        <span className="text-[28px] md:text-[48px] lg:text-[56px] md:px-10">
          <span>{"Онлайн платформа "}</span>
          <span className="">
            {"для "}
            <span>
              <span className="linear-wipe">{"образовательных "}</span>
              <span className="text-white">{" центров"}</span>
            </span>
          </span>
        </span>
      )}
    </div>
  );
}
