import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function AuthButtons({ theme }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      style={{ color: theme.textColor }}
      className="hidden md:flex flex-row items-center text-[14px] gap-5"
    >
      <button onClick={() => navigate("/signIn")} className="px-3 py-2">
        {t("signIn")}
      </button>
      <button
        style={{ borderColor: theme.borderColor }}
        onClick={() => navigate("/signUp")}
        className="px-3 py-2 rounded-xl border-[1px] border-green-800"
      >
        {t("signUp")}
      </button>
    </div>
  );
}
