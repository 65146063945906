import { motion } from "framer-motion";
import GroupStatus from "../GroupStatus";
import { useState } from "react";
import { getLessonTimesString } from "../../../utils/lessonTime";
// import { Modal, Button, Text, Row, Checkbox } from "@nextui-org/react";
import { getMoneyPattern } from "../../../utils/regex";
import { joinedDate } from "../../../utils/date";

export default function GroupCard({ group }) {
  const [visible, setVisible] = useState(false);
  const handler = () => setVisible(true);

  const closeHandler = () => {
    setVisible(false);
  };
  const [showHidden, setShowHidden] = useState(false);
  return (
    <>
      <div
        onClick={handler}
        className='relative w-[340px] cursor-pointer h-[280px] bg-blue-500 rounded-2xl overflow-hidden'
      >
        <div
          style={{ backgroundColor: group[group.type].color.hexCode }}
          className='flex justify-center items-center w-full h-full bg-cover bg-no-repeat'
        >
          <span
            style={{ fontSize: group.name.length > 15 ? "28px" : "32px" }}
            className='mt-[-25px] text-[36px] w-full px-10 text-center font-raleway text-white font-bold'
          >
            {group.name}
          </span>
        </div>
        <div className='absolute w-full h-full z-0 bg-gradient-to-t from-[#0f0f0f]/90 via-transparent to-neutral-900/10 bottom-0' />
        <motion.div
          whileHover={{
            height: "210px",
            transition: { duration: 0.4 },
          }}
          onMouseEnter={() => setShowHidden(true)}
          onMouseLeave={() => setShowHidden(false)}
          className='flex flex-col py-5 px-6 absolute w-full h-[80px] z-10 backdrop-blur-lg bg-neutral-900/50 rounded-2xl bottom-0'
        >
          <GroupStatus status={group.status} />

          <span className='text-[16px] text-white font-raleway font-semibold'>
            {group.name}
          </span>
          <span
            //   style={{ color: group.subject.color.lightHexCode }}
            className='text-[14px] font-space font-semibold text-amber-500'
          >
            {group[group.type].name}
          </span>
          {showHidden && (
            <div className='flex flex-col gap-1 text-white mt-3 font-space'>
              <div className='flex flex-row text-[12px] gap-3'>
                <span className=' text-neutral-300'>{"O'qituvchi: "}</span>{" "}
                <span className='font-bold'>{group.teacherFullName}</span>
              </div>
              {/* <div className="flex flex-row text-[12px] gap-3">
              <span>{"Guruh narxi: "}</span> <span>{group.price}</span>
            </div>
            <div className="flex flex-row text-[12px] gap-3">
              <span>{"Dars boshlanish sanasi: "}</span>{" "}
              <span>{group.teacherFullName}</span>
            </div> */}
              <div className='flex flex-row text-[12px] gap-3'>
                <span className=' text-neutral-300'>{"Dars vaqtlari: "}</span>{" "}
              </div>
              <div className='flex flex-col text-[12px] items-start'>
                {getLessonTimesString(group.lessonTimes).map((item, index) => (
                  <div
                    key={index}
                    className={
                      getLessonTimesString(group.lessonTimes).length >= 1
                        ? "flex flex-row gap-2"
                        : "flex flex-col items-start"
                    }
                  >
                    <span className=' text-orange-500'>{item.day}</span>
                    <span className='font-bold dark:text-foresty-white'>
                      {item.time}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* <div className="absolute flex flex-row items-center py-1 gap-3 bottom-1 right-3">
          <div className="text-[12px] flex flex-row gap-1 items-center font-space font-bold text-neutral-400">
            <FontAwesomeIcon
              icon={faHeart}
              className="text-[16px] text-rose-500"
            />
            <span>123</span>
          </div>
          <div className="text-[12px] flex flex-row gap-1 items-center font-space font-bold text-neutral-400">
            <FontAwesomeIcon
              icon={faEye}
              className="text-[16px] text-neutral-500"
            />
            <span>12k</span>
          </div>
        </div> */}
        </motion.div>
      </div>
      {/* <Modal
        width='500px'
        closeButton
        aria-labelledby='modal-title'
        open={visible}
        onClose={closeHandler}
        css={{
          backgroundColor: "#151515",
        }}
      >
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <div className='flex flex-col w-full gap-2 px-8 font-space'>
            <div className='flex flex-col w-full items-center'>
              <Text
                id='modal-title'
                size={18}
                className=' text-white font-space font-bold'
              >
                {group.name}
              </Text>
              <span
                //   style={{ color: group.subject.color.lightHexCode }}
                className='text-[14px] font-space font-semibold text-amber-500 mb-5'
              >
                {group.subject.name}
              </span>
            </div>
            <div className='flex flex-row text-[12px] gap-3'>
              <span className=' text-neutral-300'>{"O'qituvchi: "}</span>{" "}
              <span className='font-bold'>{group.teacherFullName}</span>
            </div>
            <div className='flex flex-row gap-2'>
              <div className='flex flex-row text-[12px] gap-3'>
                <span className=' text-neutral-300'>{"Dars vaqtlari: "}</span>{" "}
              </div>
              <div className='flex flex-col text-[12px] items-start'>
                {getLessonTimesString(group.lessonTimes).map((item, index) => (
                  <div
                    key={index}
                    className={
                      getLessonTimesString(group.lessonTimes).length >= 1
                        ? "flex flex-row gap-2"
                        : "flex flex-col items-start"
                    }
                  >
                    <span className=' text-orange-500'>{item.day}</span>
                    <span className='font-bold dark:text-foresty-white'>
                      {item.time}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className='flex flex-row items-center text-[12px] gap-3'>
              <span className=' text-neutral-300'>{"Guruh narxi: "}</span>{" "}
              <span className='font-bold text-[14px]'>{`${getMoneyPattern(
                group.price
              )} so'm /oyiga`}</span>
            </div>
            <div className='flex flex-row text-[12px] gap-3'>
              <span className=' text-neutral-300'>
                {"Dars boshlanish sanasi: "}
              </span>{" "}
              <span className='font-bold'>{`${joinedDate(
                group.startDate
              )}`}</span>
            </div>
            {group.duration != -1 && (
              <div className='flex flex-row text-[12px] gap-3'>
                <span className=' text-neutral-300'>
                  {"Guruh davomiyligi: "}
                </span>{" "}
                <span className='font-bold'>{`${group.duration} oy`}</span>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat color='error' onPress={closeHandler}>
            {"Yopish"}
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
}
