import { Avatar } from "@nextui-org/react";
import React from "react";
import img from "../../../assets/logo/foresty_green.png";
// import TableRating from "../../tableRating";
import { getProfileAvatar } from "../../../utils/profile";

export default function TeacherCard({ teacher }) {
  return (
    <div className='flex flex-col items-center justify-center font-space gap-5 w-[280px] h-[300px] bg-neutral-700/20 backdrop-blur-lg border-white/10 border-[0.3px] p-8 rounded-[30px]'>
      <div className='flex flex-col items-center gap-8'>
        <Avatar
          style={{ height: "110px", width: "110px" }}
          src={getProfileAvatar(teacher?.profilePhoto)}
          color='success'
          isBordered
          className='z-30'
        />
        <span className='text-[16px] text-center font-semibold text-white'>{`${
          teacher?.fullName.split(",")[0]
        } ${teacher?.fullName.split(",")[1]} ${
          teacher?.fullName.split(",")[2]
        }`}</span>
      </div>
      {/* <TableRating ratingValue={teacher?.rating} /> */}
    </div>
  );
}
