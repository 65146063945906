import React, { useState } from "react";
import { Cross as Hamburger } from "hamburger-react";
import logotype from "../../assets/logotype/logotype1.svg";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import { ArrowDownIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { ChevronDoubleDownIcon } from "@heroicons/react/24/solid";
import LangSwitcher from "./langSwitcher";
import { useTranslation } from "react-i18next";

export default function MainNavbar() {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <div className='font-space flex flex-row justify-between md:grid md:grid-cols-2 max-w-[1280px] items-center w-full md:px-14 px-8 py-5 md:py-8'>
        <div className='h-[60px] w-fit'>
          <a
            style={{
              zIndex: 102,
              position: isOpen && "fixed",
              left: isOpen && "32px",
              // top: isOpen && "23px",
            }}
            href='/'
            className='w-fit'
          >
            <img src={logotype} className='w-[130px] md:w-[180px]' />
          </a>
        </div>
        {/* <div className="hidden md:flex flex-row w-full justify-center items-center text-white  text-[15px] gap-5 mt-5 md:visible invisible">
        <span>Bosh sahifa</span>
        <span>Biz haqimizda</span>

        <span>Bog'lanish</span>
      </div> */}
        <div className='hidden md:flex flex-row justify-end items-center  mt-5 text-[14px] gap-5'>
          <LangSwitcher />
          <button
            onClick={() => navigate("/signIn")}
            className='text-white/80 px-3 py-2 hover:text-white'
          >
            {t("signIn")}
          </button>
          <button
            onClick={() => navigate("/signUp")}
            className='px-3 py-2 rounded-xl border-[1px] hover:border-white/80 hover:text-white text-green-200 border-green-800'
          >
            {t("signUp")}
          </button>
        </div>
        <div
          style={{
            zIndex: 102,
            position: isOpen && "fixed",
            right: isOpen && "30px",
            // top: isOpen && "23px",
          }}
          className='md:hidden flex stroke-[1px]'
        >
          <Hamburger
            toggled={isOpen}
            toggle={setOpen}
            color='#fff'
            size={24}
            className='height-[1px]'
          />
        </div>

        <div className='absolute w-full h-[80px] bg-gradient-to-b from-[#151515] left-0 bottom-[-80px]' />
      </div>
      <motion.div
        animate={{ opacity: isOpen ? 100 : 0 }}
        transition={{
          opacity: { ease: "easeInOut" },
          layout: { duration: 1 },
        }}
        style={{
          zIndex: isOpen ? 101 : -10,
          display: !isOpen && "none",
        }}
        className='fixed flex items-center justify-center w-screen h-screen backdrop-blur-2xl bg-[#000b00]/80'
      >
        <div className='flex flex-col items-center justify-center w-[350px] h-[500px]'>
          {/* <div className="mt-8">
            {" "}
            <Logo height={"40px"} color={"#fff"} />
          </div> */}
          {/* <div className="flex flex-col items-center justify-center w-full h-full"> */}
          <div className='flex flex-col justify-end items-center  mt-5 text-[14px] gap-5'>
            <button
              onClick={() => navigate("/signIn")}
              className='text-white/80 px-3 py-2 hover:text-white'
            >
              {t("signIn")}
            </button>
            <button
              onClick={() => navigate("/signUp")}
              className='px-3 py-2 rounded-xl border-[1px] hover:border-white/80 hover:text-white text-green-200 border-green-800'
            >
              {t("signUp")}
            </button>
            <LangSwitcher />
            {/* </div> */}
          </div>
        </div>
      </motion.div>
    </>
  );
}
