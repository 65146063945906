import React from "react";
import TeacherCard from "../teacher/TeacherCard";

export default function Teachers({ teachers }) {
  return (
    <div className='flex flex-row justify-center flex-wrap gap-8 w-full bg-neutral-700/20 backdrop-blur-lg border-white/10 border-[0.3px] p-8 rounded-2xl '>
      {teachers &&
        teachers.map((el, index) => <TeacherCard key={index} teacher={el} />)}
    </div>
  );
}
