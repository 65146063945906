import React from "react";

export default function GroupStatus({ status }) {
  switch (status) {
    case "COMPLETED":
      return (
        <span className="absolute z-20 border-purple-500 border-[1px] backdrop-blur-md bg-purple-500/80 text-white px-[8px] py-[2px] top-2 right-2 text-[10px] rounded-full">
          {status}
        </span>
      );

    case "NEW":
      return (
        <span className="absolute z-20 border-[#ffa700] border-[1px] backdrop-blur-md bg-[#ffa700]/80 text-white px-[8px] py-[2px] top-2 right-2 text-[10px] rounded-full">
          {status}
        </span>
      );

    case "ACTIVE":
      return (
        <span className="absolute z-20 border-green-500 border-[1px] backdrop-blur-md bg-green-500/80 text-white px-[8px] py-[2px] top-2 right-2 text-[10px] rounded-full">
          {status}
        </span>
      );
  }
}
