export function getPhoneNumberPattern(num) {
  let cleanedData = cleanStringForRegex(num);

  if (cleanedData.match(/(\d{2})(\d{3})(\d{2})(\d{2})\d{0,9}/)) {
    return cleanedData.replace(
      /(\d{2})(\d{3})(\d{2})(\d{2})\d{0,9}/,
      "($1) $2-$3-$4"
    );
  }

  if (cleanedData.match(/(\d{2})(\d{3})(\d{1,2})/)) {
    return cleanedData.replace(/(\d{2})(\d{3})(\d{1,2})/, "($1) $2-$3");
  }

  if (cleanedData.match(/(\d{2})(\d{1,3})/)) {
    return cleanedData.replace(/(\d{2})(\d{1,3})/, "($1) $2");
  }

  return cleanedData;
}

export function getMoneyPattern(amount) {
  let cleanedData = cleanStringForRegex(amount);
  if (cleanedData.length === 9) cleanedData = cleanedData.slice(0, 8);
  return cleanedData.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function getCodePattern(code) {
  let cleanedData = cleanStringForRegex(code);
  console.log(cleanedData);
  if (cleanedData.length > 6) cleanedData = cleanedData.slice(0, 6);

  return cleanedData.replace(/^\d{6}$/, cleanedData);
}

export function cleanStringForRegex(str) {
  return ("" + str).replace(/\D+/g, "");
}

export function getAgreementPattern(val) {
  return val.replace(
    /^(?![0-9][0-9]?$|^100$).*$/,
    val.substring(0, val.length - 1)
  );
}

export function matchPassword(val) {
  return val.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/);
}

export function matchEmail(val) {
  return val.match(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  );
}
