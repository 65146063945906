import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEducationCenterByUsername } from "./eduCenterSlice";
import { useNavigate, useParams } from "react-router-dom";
import { getLogoSrc } from "../../utils/profile";
import FixedNavbar from "../../components/EducationCenter/FixedNavbar";
import Header from "../../components/EducationCenter/Header";
import FooterSection from "../../components/section/FooterSection";
import MainSection from "../../components/EducationCenter/MainSection";

const EducationCenterPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { educationCenter, loading } = useSelector((state) => state.eduCenter);
  useEffect(() => {
    if (!id.startsWith("@")) {
      navigate("/404");
    } else {
      dispatch(getEducationCenterByUsername(id.slice(1)));
    }
  }, []);

  useEffect(() => {
    if (educationCenter) {
      document.title = educationCenter?.name;
    }
  }, [educationCenter]);
  return (
    <>
      <div
        style={{
          opacity: !loading ? 0 : 100,
        }}
        className='w-screen h-screen z-30 fixed bg-white flex justify-center items-center transition-opacity duration-500 ease-in-out'
      >
        <img
          src={getLogoSrc(educationCenter?.logo)}
          className='w-[130px] md:w-[210px]'
        />
      </div>

      <div style={{ display: loading && "none" }} className='relative w-screen'>
        <div className='fixed bg-[#0f0f0f] h-screen w-full'></div>
        <div className='w-full absolute bg-neutral-900 h-[920px]'>
          <div className='absolute w-full h-full bg-gradient-to-t from-[#0f0f0f] to-black/30 bottom-0 z-10' />
          <div className='absolute w-full h-full bg-gradient-to-r from-[#0f0f0f] via-transparent to-[#0f0f0f] left-0 z-10' />
          <div
            style={{
              backgroundPosition: "center",
              backgroundImage:
                educationCenter?.headerImage &&
                `url(https://platform.foresty.uz/v1/api/image/${educationCenter?.headerImage.id})`,
              backgroundColor: !educationCenter?.headerImage && "#20472d",
            }}
            className='w-full h-full absolute z-0 bg-cover bg-no-repeat'
          />

          {/* <div className="w-full h-full absolute z-0 bg-cover bg-no-repeat bg-[url('https://archello.s3.eu-central-1.amazonaws.com/images/2018/08/24/12-story-Commercial-Office-Building-1.1535133699.4952.jpg')]" /> */}
        </div>
        {/*bg-[url('https://ahsarch.com/wp-content/uploads/2017/08/North-1080x675.jpg')]*/}

        <div className='flex flex-col w-full absolute z-30 overflow-hidden'>
          {/*<Navbar/>*/}
          <FixedNavbar />
          <div className='flex flex-col w-full px-1 md:px-5 lg:px-14'>
            <Header data={educationCenter} />
            <MainSection center={educationCenter} username={id} />
          </div>
          <FooterSection />
        </div>
      </div>
    </>
  );
};

export default EducationCenterPage;
