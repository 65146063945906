import React from "react";
import { Parallax } from "react-scroll-parallax";
import settings_shot from "../../assets/center-foresty-shots/main_shot.png";
import foresty_main from "../../assets/foresty-shots/main_shot.png";
import { useTranslation } from "react-i18next";

export default function MainSection() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center w-full bg-white">
      <div className="flex flex-col w-[95%] sm:w-[90%] md:w-[90%] lg:w-full max-w-[1280px] items-center lg:px-8 py-10 mt-10 md:mt-[80px]">
        <div className="flex flex-col items-center text-center w-full">
          <span className="text-[32px] md:text-[56px] lg:text-[48px] font-extrabold font-raleway text-[#002d00]">
            {"Foresty"}
          </span>
          <span className="text-[28px] md:text-[56px] lg:text-[36px] font-bold font-raleway text-[#002d00]">
            {t("mainSecTitle")}
          </span>

          {/* <span className="text-[24px] md:text-[28px] lg:text-[48px] font-bold font-raleway text-[#20472d] mt-[-10px]">
            {" shaxsiy veb-sahifa "}
          </span>

          <span className="text-[18px] md:text-[22px] lg:text-[24px] text-[#00862d] font-bold font-raleway">
            {"va boshqalar"}
          </span> */}
        </div>
        <div className="flex flex-col mt-10 w-full gap-8">
          <div className="flex flex-col sm:grid sm:grid-cols-2 gap-5 w-full bg-[#1515150d] overflow-hidden">
            <div className="flex relative w-full h-[150px] sm:h-full md:h-[480px] p-5 order-last sm:order-first">
              <Parallax
                translateY={["0px", "-50px"]}
                // speed={10}
                className="absolute z-10 w-[100%] h-full sm:w-[180%] md:w-[200%] lg:w-[130%] md:left-[-85%] sm:left-[-70%] lg:left-[-28%] left-0"
              >
                <img
                  src={settings_shot}
                  className="absolute w-[100%] top-0 sm:top-[20%] object-cover bg-no-repeat bg-contain z-10 bottom-0"
                />
              </Parallax>
            </div>
            <div className="flex flex-col items-start font-raleway gap-3 py-[80px] pb-[10px] sm:pb-[60px] px-[50px] pl-[60px]">
              <span className="text-[36px] md:text-[40px] lg:text-[48px] text-green-500 font-bold">
                {t("dashboardTitle")}
              </span>
              <span className="text-[14px] md:text-[16px] lg:text-[18px] text-neutral-500/80 font-raleway font-medium">
                {t("dashboardText")}
              </span>
              <a
                href="https://center.foresty.uz/login/demo"
                target="_blank"
                className="mt-1 md:mt-5 text-[14px] md:text-[16px] font-semibold bg-green-500 hover:bg-green-600 text-white px-8 py-3 rounded-full"
              >
                {t("dashboardBtn")}
              </a>
            </div>
          </div>

          <div className="flex flex-col sm:grid sm:grid-cols-2 gap-5 w-full bg-[#1515150d] overflow-hidden">
            <div className="flex flex-col items-start font-raleway gap-3 py-[80px] pb-[10px] sm:pb-[60px] px-[50px] pl-[60px] lg:ml-10">
              <span className="text-[36px] md:text-[40px] lg:text-[48px] text-green-500 font-bold">
                {t("webpageTitle")}
              </span>
              <span className="text-[14px] md:text-[16px] lg:text-[18px] text-neutral-500/80 font-raleway font-medium">
                {t("webpageText")}
              </span>
              <a
                href="https://foresty.uz/@foresty"
                target="_blank"
                className="mt-1 md:mt-5 text-[14px] md:text-[16px] font-semibold bg-green-500 hover:bg-green-600 text-white px-8 py-3 rounded-full"
              >
                {t("webpageBtn")}
              </a>
            </div>
            <div className="flex relative w-full h-[150px] sm:h-full md:h-[480px] p-5 order-last sm:order-last">
              <Parallax
                translateY={["0px", "-50px"]}
                // speed={10}
                className="absolute z-10 w-[100%] h-full sm:w-[180%] md:w-[200%] lg:w-[130%] md:right-[-85%] sm:right-[-70%] lg:right-[-28%] right-0"
              >
                <img
                  src={foresty_main}
                  className="absolute w-[100%] top-0 sm:top-[20%] object-cover bg-no-repeat bg-contain z-10 bottom-0"
                />
              </Parallax>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
