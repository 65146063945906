export default function MainSectionMenu({ categories, selectCategory }) {
  return (
    <div className="flex flex-row flex-wrap backdrop-blur-md font-space gap-1 md:gap-2 justify-center bg-neutral-700/10 border-[0.3px] border-white/10 rounded-full w-fit p-2 ">
      {categories.map((item, index) => {
        return (
          <button
            onClick={() => selectCategory(index)}
            className={
              item.selected
                ? "px-3 py-2 md:px-5 md:py-2 text-white text-[12px] md:text-[14px] bg-gradient-to-t from-green-700 to-green-500 border-[1px] border-green-500 rounded-full"
                : " px-3 py-2 md:px-5 md:py-2 text-white text-[12px] md:text-[14px] border-[1px] border-transparent rounded-full"
            }
            key={index}
          >
            {item.text}
          </button>
        );
      })}
    </div>
  );
}
