import Logo from "../logo";
import { Avatar } from "@nextui-org/react";
import React from "react";
import AuthButtons from "./AuthButtons";
import { Cross as Hamburger } from "hamburger-react";
import { useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import LangSwitcher from "../MainPage/langSwitcher";

export default function FixedNavbar() {
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{ zIndex: 105 }}
        className='fixed backdrop-blur-lg w-full px-8 md:px-14 border-b-[0.1px] border-b-white/10 flex flex-row justify-between items-center py-5'
      >
        <div className='h-[40px] w-fit mt-5'>
          <a
            // style={{
            //   zIndex: 102,
            //   position: isOpen && "fixed",
            //   left: isOpen && "32px",
            //   // top: isOpen && "23px",
            // }}
            href='/'
            className='w-fit '
          >
            <Logo color='#fff' height='40px' />
          </a>
        </div>
        {/* <button className="flex flex-row text-white items-center gap-3 mt-2 border-[0.3px] border-transparent hover:border-white/20 p-2 rounded-full">
        <div className="font-space text-[14px] flex flex-col items-end gap-[-2px] ml-6">
          <span>Jakhongir</span>
          <span className="text-[12px] font-bold">jakhon99dev@gmail.com</span>
        </div>
        <Avatar
          className="cursor-pointer"
          size="lg"
          src="https://i.pravatar.cc/150?u=a042581f4e25056704b"
          color="success"
          bordered
        />
      </button> */}
        <div
          // style={{
          //   zIndex: 102,
          //   position: isOpen && "fixed",
          //   right: isOpen && "30px",
          //   // top: isOpen && "23px",
          // }}
          className='md:hidden flex stroke-[1px] md:mr-0 mr-[-8px] mt-5'
        >
          <Hamburger
            toggled={isOpen}
            toggle={setOpen}
            color='#eee'
            size={24}
            className='height-[1px]'
          />
        </div>
        <div className='hidden md:flex flex-row gap-5 items-center justify-end mt-5'>
          <LangSwitcher />
          <AuthButtons theme={{ textColor: "#fff", borderColor: "#888" }} />
        </div>
      </div>
      <motion.div
        animate={{ opacity: isOpen ? 100 : 0 }}
        transition={{
          opacity: { ease: "easeInOut" },
          layout: { duration: 1 },
        }}
        style={{
          zIndex: isOpen ? 101 : -10,
          display: !isOpen && "none",
        }}
        className='fixed flex items-center justify-center w-screen h-screen backdrop-blur-2xl bg-[#000b00]/80'
      >
        <div className='flex flex-col items-center justify-center w-[350px] h-[500px]'>
          {/* <div className="mt-8">
            {" "}
            <Logo height={"40px"} color={"#fff"} />
          </div> */}
          {/* <div className="flex flex-col items-center justify-center w-full h-full"> */}
          <div className='flex flex-col justify-end items-center  mt-5 text-[14px] gap-5'>
            <button
              onClick={() => navigate("/signIn")}
              className='text-white/80 px-3 py-2 hover:text-white'
            >
              Kirish
            </button>
            <button
              onClick={() => navigate("/signUp")}
              className='px-3 py-2 rounded-xl border-[1px] hover:border-white/80 hover:text-white text-green-200 border-green-800'
            >
              {"Ro'yhatdan o'tish"}
            </button>
            <LangSwitcher />
            {/* </div> */}
          </div>
        </div>
      </motion.div>
    </>
  );
}
