import { Route, Routes } from "react-router-dom";
import MainPage from "./pages/MainPage/MainPage";
import EducationCenterPage from "./pages/EducationCenterPage/EducationCenterPage";
import Page404 from "./pages/Page404";

function App() {
  return (
    <Routes>
      <Route path='/:id/*' element={<EducationCenterPage />} />
      <Route exact path='/404' element={<Page404 />} />

      <Route exact path='/' element={<MainPage />} />

      {/* <Route exact path="*" element={<Navigate to="/404" replace />} /> */}
    </Routes>
  );
}

export default App;
