import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../config/Api";
import { toast } from "react-toastify";

export const getEducationCenterByUsername = createAsyncThunk(
  "eduCenter/getByUsername",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.get(`/public/educationCenter/${body}`);
      return response.data;
    } catch (err) {
      if (err.response.status === 404) {
        window.location.href = "/404";
      }
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSubject = createAsyncThunk(
  "eduCenter/delete",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/subject/${body?.id}`);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const eduCenterSlice = createSlice({
  name: "eduCenter",
  initialState: {
    educationCenter: null,

    loading: false,
    error: null,
  },
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      ///------------ GET subjects ------------------/////
      .addCase(getEducationCenterByUsername.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEducationCenterByUsername.fulfilled, (state, action) => {
        state.loading = false;
        state.educationCenter = action.payload?.data;
      })
      .addCase(getEducationCenterByUsername.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    //   ///------------ CREATE subjects ------------------/////
    //   .addCase(createSubject.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(createSubject.fulfilled, (state, { payload }) => {
    //     state.loading = false;
    //     state.subjects = [payload?.data, ...state.subjects];
    //     toast.success(payload?.message);
    //   })
    //   .addCase(createSubject.rejected, (state, { payload }) => {
    //     state.loading = false;
    //     state.error = payload;
    //     toast.error(payload?.message);
    //   })
  },
});

export const { resetError } = eduCenterSlice.actions;

export default eduCenterSlice.reducer;
