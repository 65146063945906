import { Avatar } from "@nextui-org/react";
import React, { useState } from "react";
import img from "../../assets/logo/foresty_green.png";
import { motion } from "framer-motion";
import {
  faFacebook,
  faInstagram,
  faTelegram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import SocialLinkButton from "./SocialLinkButton";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import Clipboard from "clipboard";
import CopyToClipboard from "react-copy-to-clipboard";
import { getLogoSrc } from "../../utils/profile";

export default function Header({ data }) {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    const clipboard = new Clipboard("oioi");
    clipboard.on("success", () => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
    clipboard.on("error", () => {
      alert("Failed to copy!");
    });
  };

  return (
    <div className='flex flex-col relative w-full h-[340px] items-center pt-14 font-space mt-[200px]'>
      {/*<div*/}
      {/*    className="absolute w-[300px] h-[300px] rounded-full bg-red-500 left-[-15%] blur-[80px] top-[50%] translate-y-[-50%]"/>*/}
      <motion.div
        // initial={{ opacity: 0, y: 30 }}
        // animate={{ opacity: 1, y: 0 }}
        // transition={{ duration: 0.8 }}
        className='w-[130px] h-[130px] relative'
      >
        <Avatar
          style={{ height: "100%", width: "100%" }}
          src={getLogoSrc(data?.logo)}
          color='success'
          isBordered
          className='z-30'
        />
        {/* <div
                className="absolute w-[210px] h-[30px] bg-gradient-to-t from-green-500 bottom-0 blur-2xl left-[50%] translate-x-[-50%]"/> */}
      </motion.div>

      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.3, delay: 0.5 }}
        className='text-[24px] text-white font-black mt-5'
      >
        {data?.name}
      </motion.span>
      <div className='relative flex'>
        <CopyToClipboard
          text={`https://foresty.uz/@${data?.username}`}
          onCopy={() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 1000);
          }}
        >
          <button className='mt-2 font-raleway font-bold text-neutral-300 hover:text-green-500'>{`@${data?.username}`}</button>
        </CopyToClipboard>
        {copied && (
          <span className='absolute bottom-[-30px] text-white rounded-full px-3 py-1 bg-green-500 text-[12px]'>
            {"Copied!"}
          </span>
        )}
      </div>

      <div className='flex flex-row gap-5 mt-10'>
        {data?.facebook != null && (
          <SocialLinkButton icon={faFacebook} to={data?.facebook} />
        )}
        {data?.instagram != null && (
          <SocialLinkButton icon={faInstagram} to={data?.instagram} />
        )}
        {data?.telegram != null && (
          <SocialLinkButton icon={faTelegram} to={data?.telegram} />
        )}
        {data?.youtube != null && (
          <SocialLinkButton icon={faYoutube} to={data?.youtube} />
        )}
        {data?.twitter != null && (
          <SocialLinkButton icon={faTwitter} to={data?.twitter} />
        )}
        {data?.website != null && (
          <SocialLinkButton icon={faGlobe} to={data?.website} />
        )}
      </div>
    </div>
  );
}
