import React from "react";
import MainNavbar from "../../components/MainPage/MainNavbar";
import MainHeader from "../../components/MainPage/MainHeader";
import { Parallax } from "react-scroll-parallax";
import main_shot from "../../assets/center-foresty-shots/main_shot.png";
import MainSection from "../../components/section/MainSection";
import DetailsSection from "../../components/section/DetailsSection";
import ContactSection from "../../components/section/ContactSection";
import FooterSection from "../../components/section/FooterSection";

const MainPage = () => {
  return (
    <div className='flex flex-col items-center w-screen selection:text-[#002200] selection:bg-green-500'>
      <div className='flex flex-col relative w-full items-center overflow-hidden bg-gradient-to-b from-neutral-900 via-[#151515] to-black'>
        <MainNavbar />
        <div className='flex flex-col max-w-[1280px] w-full items-center'>
          <MainHeader />

          <div className='flex flex-col w-full gap-5 md:mt-14 relative h-[80vw] sm:h-[70vw] md:h-[60vw] lg:h-[650px]'>
            <div className='header-img-back w-[80%] h-[30%] lg:w-[800px] lg:h-[300px] absolute z-0 top-[-10px] md:top-[-80px] left-[50%] translate-x-[-50%] rounded-full' />
            <Parallax
              translateY={["0px", "-100px"]}
              // speed={10}
              className='h-fit z-10 w-[180vw] sm:w-[130vw] md:w-[120%] lg:w-full bottom-[-100px] absolute'
            >
              <img
                src={main_shot}
                className='md:w-[120%] lg:w-full z-10 bottom-0'
              />
            </Parallax>
          </div>
        </div>
        <div className='absolute z-20 w-full h-[130px] bg-gradient-to-t from-[#151515] via-[#151515]/50 left-0 bottom-0' />
      </div>
      <MainSection />
      <DetailsSection />
      <ContactSection />
      <FooterSection />
    </div>
  );
};

export default MainPage;
