import React from "react";
import { getPhoneNumberPattern } from "../../../utils/regex";

export default function Info({ center }) {
  return (
    <div className="flex flex-col font-space items-start text-white gap-5 w-full bg-neutral-700/20 backdrop-blur-lg border-white/10 border-[0.3px] px-10 py-8 rounded-2xl ">
      <span className="text-[22px] md:text-[28px] font-bold ml-5">
        {center?.name}
      </span>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row gap-3 text-[14px] md:text-[16px] items-center">
          <span className="font-bold text-orange-400">Owner</span>
          <span className="text-[12px] md:text-[14px]">{center?.owner}</span>
        </div>
        <div className="flex flex-row gap-3 text-[14px] md:text-[16px] items-center">
          <span className="font-bold text-orange-400">Telefon raqam</span>
          <span className="text-[12px] md:text-[14px]">{`+998 ${getPhoneNumberPattern(
            center?.phoneNumber
          )}`}</span>
        </div>
      </div>
      <pre className="text-[12px] md:text-[14px] font-space text-neutral-300 font-normal break-words whitespace-pre-wrap">
        {center?.description}
      </pre>
    </div>
  );
}
