import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Languagedetector from "i18next-browser-languagedetector";

i18next
  .use(initReactI18next)
  .use(Languagedetector)
  .init({
    fallbackLng: "en",
    resources: {
      en: {
        translation: {
          learn: "React",
          signIn: "Sign In",
          signUp: "Sign Up",
          mainSecTitle: "Efficient. Comfortable. Easy.",
          dashboardTitle: "Dashboard",
          dashboardText:
            "Management part of educational centers on the Foresty platform. In this part, you can manage all the activities, subjects, rooms and information of employees, teachers, students, groups and their payment processes in the most convenient to use foresty dashboard.",
          dashboardBtn: "Try it",
          webpageTitle: "Web Page",
          webpageText:
            "The most important part of the Foresty platform is the personal web page of the educational centers. In this case, the educational centers will have a username for the educational center on the foresty platform and will be able to openly display information about the educational center's groups, teachers and the center to its users.",
          webpageBtn: "Try it",
          detailSecTitle1: "Gorgeous design.",
          detailSecTitle2: "Excellent management.",
          homeDetail:
            "The home page consists of a number of widgets that highlight the required learning center features.",
          calendarDetail:
            "With the best-looking lesson schedule, you can easily follow the classes of your school's groups.",
          usersDetail:
            "Full data management of employees, teachers, students. Monitor their activities. Special positions for employees. Private cabinets for them.",
          paymentDetail:
            "Group, faculty and staff payment management. Automatic calculation of their data. Retention of payment information even if student information is deleted.",
          modes: "Light and Dark modes",
          trial: "Start a free 30-day trial",
          trialText: "Contact us and get a 30-day free trial of foresty.",
          planLimits1: "500 active students",
          planLimits2: "50 active groups",
          planLimits3: "30 active teachers",
          planLimits4: "20 rooms",
          planLimits5: "15 subjects",
          planLimits6: "8 active employees",

          copyright: "All rights reserved.",
        },
      },
      uz: {
        translation: {
          learn: "Reactcha",
          signIn: "Kirish",
          signUp: "Ro'yhatdan o'tish",
          mainSecTitle: "Samarali. Qulay. Oson.",
          dashboardTitle: "Boshqaruv paneli",
          dashboardText:
            "Foresty platformasidagi o’quv markazlarni boshqaruv qismi. Ushbu qismda siz o’quv markazingizdagi barcha faoliyatlar, fanlar, xonalar hamda xodimlar, o’qituvchilar, o’quvchilar, guruhlar ma’lumotlarini va ularning to’lov jarayonlarini foydalanish uchun eng qulay tarzda tayyorlangan foresty ning dashboard qismi orqali boshqarishingiz mumkin.",
          dashboardBtn: "Sinab ko'rish",
          webpageTitle: "Veb sahifa",
          webpageText:
            "Foresty platformasining eng ahamiyatli qismi ya’ni o’quv markazlarning shaxsiy web sahifasi. Bunda o’quv markazlar foresty platformasida o’quv markaz uchun usernamega ega bo’ladilar va o’quv markazning guruhlari, o’qituvchilari va markaz haqidagi ma’lumotlarini foydalanuvchilariga ochiq tarzda namoyish qilishlari mumkin bo’ladi.",
          webpageBtn: "Ko'rish",

          detailSecTitle1: "Ajoyib dizayn.",
          detailSecTitle2: "Sifatli boshqaruv.",

          homeDetail:
            "Bosh sahifa kerakli o'quv markaz ma'umotlarini yoritib beruvchi bir qancha videjedlardan tashkil topgan.",
          calendarDetail:
            "Eng ajoyib ko'rinishga ega bo'lgan darslar jadvali orqali siz o'quv markazingiz guruhlarining darslarini tushunarli tarzda kuzatib borishingiz mumkin.",
          usersDetail:
            "Xodimlar, o'qituvchilar, o'quvchilar ma'lumotlarini to'liq boshqarish. Ularning faoliyatlarini kuzatib borish. Xodimlar uchun maxsus lavozimlar. Ular uchun shaxsiy kabinetlar.",
          paymentDetail:
            "Guruh, o'qituvchilar va xodimlarning to'lovlarini boshqarish. Ularning ma'lumotlarini avtomatik hisob-kitob qilish. O'quvchi ma'lumotlari o'chgan taqdirda ham to'lov ma'lumotlarini saqlab qolish.",
          modes: "Kunduzgi va tungi rejim",
          trial: "30 kunlik bepul sinovni boshlang",
          trialText:
            "Biz bilan bog'laning va forestyda 30 kunlik bepul sinov muddatini qo'lga kiriting.",
          planLimits1: "500 ta aktiv o'quvchilar",
          planLimits2: "50 ta aktiv guruhlar",
          planLimits3: "30 ta aktiv o'qituvchilar",
          planLimits4: "20 ta xonalar",
          planLimits5: "15 ta fanlar",
          planLimits6: "8 ta aktiv xodimlar",

          copyright: "Barcha huquqlar himoyalangan.",
        },
      },
      ru: {
        translation: {
          learn: "Reactcha",
          signIn: "Войти",
          signUp: "Зарегистрироваться",
          mainSecTitle: "Эффективный. Комфортный. Легкий.",
          dashboardTitle: "Панель приборов",
          dashboardText:
            "Управляющая часть образовательных центров на платформе Foresty. В этой части вы можете управлять всеми видами деятельности, предметами, комнатами и информацией о сотрудниках, преподавателях, учениках, группах и их платежных процессах в наиболее удобной для использования лесной панели.",
          dashboardBtn: "Попробуйте",
          webpageTitle: "Веб страница",
          webpageText:
            "Важнейшей частью платформы Foresty являются персональные страницы образовательных центров. В этом случае образовательные центры будут иметь имя пользователя образовательного центра на платформе foresty и смогут открыто отображать информацию о группах образовательного центра, преподавателях и центре своим пользователям.",
          webpageBtn: "Попробуйте",

          detailSecTitle1: "Великолепный дизайн.",
          detailSecTitle2: "Отличное управление.",
          homeDetail:
            "Домашняя страница состоит из ряда виджетов, которые выделяют необходимые функции учебного центра.",
          calendarDetail:
            "Благодаря лучшему расписанию уроков вы можете легко следить за занятиями групп вашей школы.",
          usersDetail:
            "Полное управление данными сотрудников, преподавателей, студентов. Следите за их деятельностью. Специальные должности для сотрудников. Отдельные кабинеты для них.",
          paymentDetail:
            "Управление оплатой труда группы, преподавателей и сотрудников. Автоматический расчет их данных. Сохранение платежной информации, даже если информация о студенте удалена.",
          modes: "Светлый и темный режимы",
          trial: "Начать бесплатную 30-дневную пробную версию",
          trialText:
            "Свяжитесь с нами и получите 30-дневную бесплатную пробную версию Foresty.",
          planLimits1: "500 активных студентов",
          planLimits2: "50 активных групп",
          planLimits3: "30 активных учителей",
          planLimits4: "20 комнат",
          planLimits5: "15 тем",
          planLimits6: "8 активных сотрудников",

          copyright: "Все права защищены.",
        },
      },
    },
  });
