import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function SocialLinkButton({ icon, to }) {
  return (
    <a
      href={to}
      target="_blank"
      className="group w-[40px] h-[40px] backdrop-blur-md rounded-full border-[1px] border-neutral-600 p-2 hover:border-green-500"
    >
      <FontAwesomeIcon
        icon={icon}
        className="group-hover:text-white text-neutral-400 w-full h-full"
      />
    </a>
  );
}
