import { CheckIcon } from "@heroicons/react/24/outline";
import { PhoneIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function ContactSection() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center w-full bg-white font-raleway">
      <div className="flex flex-col  w-[95%] sm:w-[90%] lg:px-8 lg:w-screen max-w-[1280px] items-center py-10 md:mt-8 md:mt-20 gap-14 md:mb-10">
        <div className="grid sm:grid-cols-2 grid-cols-1 gap-3 md:gap-5 w-full">
          <div className="flex flex-col justify-center items-center w-full px-[50px] py-[50px] sm:py-[40px] bg-[#f0f0f0]/50">
            <div className="flex flex-col items-center gap-3 px-10 py-8">
              <span className="text-[28px] font-bold text-center font-space text-[#00862d]">
                {t("trial")}
              </span>
              <span className="text-[14px] text-neutral-500 text-center">
                {t("trialText")}
              </span>
            </div>
            <div className="flex flex-col font-space text-[18px] gap-5">
              <div className="flex flex-row items-center gap-3">
                <PhoneIcon className="w-[25px] text-green-500" />
                <span className="text-[#002d00] font-bold">
                  {"+998 99 041 42 33"}
                </span>
              </div>
              <div className="flex flex-row items-center gap-3">
                <PhoneIcon className="w-[25px] text-green-500" />
                <span className="text-[#002d00] font-bold">
                  {"+998 90 885 76 55"}
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center w-full px-[50px] py-[40px] bg-green-500">
            <div className="flex flex-col w-full items-center gap-8 sm:gap-5 px-2 sm:px-10 py-8">
              <span className="text-[28px] sm:text-[36px] text-center font-bold font-raleway text-white">
                {"Foresty Basic Plan"}
              </span>
              <div className="flex flex-col font-space gap-3 bg-white w-full sm:w-[80%] px-8 py-8 rounded-xl">
                <div className="flex flex-row items-center gap-3 text-[14px] sm:text-[16px]">
                  <CheckIcon className=" text-green-400 stroke-[2px] w-[20px]" />
                  <span className=" text-[#20472d]">{t("planLimits1")}</span>
                </div>
                <div className="flex flex-row items-center gap-3 text-[14px] sm:text-[16px]">
                  <CheckIcon className=" text-green-400 stroke-[2px] w-[20px]" />
                  <span className=" text-[#20472d]">{t("planLimits2")}</span>
                </div>
                <div className="flex flex-row items-center gap-3 text-[14px] sm:text-[16px]">
                  <CheckIcon className=" text-green-400 stroke-[2px] w-[20px]" />
                  <span className=" text-[#20472d]">{t("planLimits3")}</span>
                </div>
                <div className="flex flex-row items-center gap-3 text-[14px] sm:text-[16px]">
                  <CheckIcon className=" text-green-400 stroke-[2px] w-[20px]" />
                  <span className=" text-[#20472d]">{t("planLimits4")}</span>
                </div>
                <div className="flex flex-row items-center gap-3 text-[14px] sm:text-[16px]">
                  <CheckIcon className=" text-green-400 stroke-[2px] w-[20px]" />
                  <span className=" text-[#20472d]">{t("planLimits5")}</span>
                </div>
                <div className="flex flex-row items-center gap-3 text-[14px] sm:text-[16px]">
                  <CheckIcon className=" text-green-400 stroke-[2px] w-[20px]" />
                  <span className=" text-[#20472d]">{t("planLimits6")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
