import { useState } from "react";
import MainSectionMenu from "./MainSectionMenu";

import Groups from "./pages/Groups";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Teachers from "./pages/Teachers";
import Info from "./pages/Info";

export default function MainSection({ center, username }) {
  const location = useLocation();
  const pathnameArr = location.pathname.split("/");
  const pathEndPoint = pathnameArr[pathnameArr.length - 1];

  const navigate = useNavigate();
  const [categories, setCategories] = useState([
    {
      text: "Guruhlar",
      selected: pathnameArr.length == 2,
      to: "/",
    },
    {
      text: "O'qituvchilar",
      selected: pathEndPoint === "teachers",
      to: "teachers",
    },
    // { text: "Fanlar", selected: false },
    // { text: "Galereya", selected: false },
    // { text: "Filiallar", selected: false },
    { text: "Markaz haqida", selected: pathEndPoint === "info", to: "info" },
  ]);

  const selectCategory = (index) => {
    setCategories(
      categories.map((item, i) => {
        if (i === index) {
          item.selected = true;
          item.to === "/"
            ? navigate(`/${username}`)
            : navigate(`/${username}/${item.to}`);
          return item;
        }
        item.selected = false;
        return item;
      })
    );
  };
  return (
    <div className='flex flex-col relative gap-8 items-center mt-14 py-8 px-2 md:px-5 w-full'>
      <div className='absolute rounded-full bg-[#00a652]/30 w-[1000px] h-[1000px] top-[40%] left-[-40%] blur-2xl -z-10'>
        <div className='absolute rounded-full bg-gradient-to-r from-[#0f0f0f] via-transparent to-[#0f0f0f] w-full h-full -z-10' />
        <div className='absolute rounded-full bg-gradient-to-t from-[#0f0f0f] via-transparent to-[#0f0f0f] w-full h-full -z-10' />
      </div>
      <div className='absolute rounded-full bg-[#00a652]/30 w-[1000px] h-[1000px] top-[5%] right-[-40%] blur-2xl -z-10'>
        <div className='absolute rounded-full bg-gradient-to-r from-[#0f0f0f] via-transparent to-[#0f0f0f] w-full h-full -z-10' />
        <div className='absolute rounded-full bg-gradient-to-t from-[#0f0f0f] via-transparent to-[#0f0f0f] w-full h-full -z-10' />
      </div>
      <MainSectionMenu
        categories={categories}
        selectCategory={selectCategory}
      />
      {/* {categories.find((el) => el.selected)?.element} */}
      <Routes>
        <Route path={`/`} element={<Groups groups={center?.groups} />} />
        <Route
          path={`/teachers`}
          element={<Teachers teachers={center?.teachers} />}
        />
        <Route path={`/info`} element={<Info center={center} />} />
      </Routes>
    </div>
  );
}
