import React from "react";
import {
  BanknotesIcon,
  CalendarDaysIcon,
  CalendarIcon,
  HomeIcon,
  MoonIcon,
  SunIcon,
  UserGroupIcon,
} from "@heroicons/react/24/solid";
import screenshot from "../../assets/screenshot.png";
import main from "../../assets/center-foresty-shots/main_shot.png";
import lightDark from "../../assets/center-foresty-shots/lightDark.png";

import gp_shot from "../../assets/center-foresty-shots/group_payment_shot.png";
import ep_shot from "../../assets/center-foresty-shots/employees_shot.png";

import calendar_shot from "../../assets/center-foresty-shots/calendar_shot2.png";
import { useTranslation } from "react-i18next";

export default function DetailsSection() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center w-full bg-[#0f0f0f] font-raleway">
      <div className="flex flex-col text-white w-[95%] sm:w-[90%] lg:px-8 lg:w-screen max-w-[1280px] items-center py-10 mt-10 sm:mt-[80px] gap-8 sm:gap-14">
        <span className="text-white text-[28px] sm:text-[36px] md:text-[48px] font-bold sm:px-0 px-10 text-center">
          {t("detailSecTitle1")}
          <br />
          <span>{t("detailSecTitle2")}</span>
        </span>
        <div className="grid sm:grid-cols-2 grid-cols-1 gap-3 sm:gap-5 w-full font-space">
          <div className="flex flex-col gap-8 items-center justify-center bg-neutral-800/80 py-14 px-8">
            <HomeIcon className="w-[56px] text-green-500 mt-5" />
            <span className="text-[14px] md:text-[16px] font-normal text-neutral-200 px-5 text-center">
              {t("homeDetail")}
            </span>
            <img src={main} className="mb-[-30px]" />
          </div>
          <div className="flex flex-col gap-8 items-center justify-center bg-neutral-800/80 py-14 px-8">
            <CalendarDaysIcon className="w-[56px] text-green-500 mt-5" />
            <span className="text-[14px] md:text-[16px] font-normal text-neutral-200 px-5 text-center">
              {t("calendarDetail")}
            </span>
            <img src={calendar_shot} className="mb-[-30px]" />
          </div>
          <div className="flex flex-col gap-8 items-center justify-center bg-neutral-800/80 py-14 px-8">
            <UserGroupIcon className="w-[56px] text-green-500 mt-5" />
            <span className="text-[14px] md:text-[16px] font-normal text-neutral-200 px-5 text-center">
              {t("usersDetail")}
            </span>
            <img src={ep_shot} className="mb-[-30px]" />
          </div>
          <div className="flex flex-col gap-8 items-center justify-center bg-neutral-800/80 py-14 px-8">
            <BanknotesIcon className="w-[56px] text-green-500 mt-5" />
            <span className="text-[14px] md:text-[16px] font-normal text-neutral-200 px-5 text-center">
              {t("paymentDetail")}
            </span>
            <img src={gp_shot} className="mb-[-30px]" />
          </div>
          <div className=" sm:col-span-2 flex flex-col gap-5 h-fit items-center justify-center bg-neutral-800/80 py-14 px-8 overflow-hidden">
            <div className="flex flex-row gap-10 md:mb-8 mb-3">
              <SunIcon className="md:w-[60px] w-[50px] text-[#ffce00] mt-5" />
              <MoonIcon className="md:w-[50px] w-[40px] text-[#00acfd] mt-5" />
            </div>
            <span className="text-[18px] md:text-[36px] font-bold text-white px-5 text-center">
              {t("modes")}
            </span>
            <div className="relative w-full h-[100px] sm:h-[210px] md:h-[300px] lg:h-[400px] ">
              <img
                src={lightDark}
                className="mb-[-30px] left-[50%] translate-x-[-50%] w-full md:w-[80%] absolute"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
